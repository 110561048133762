
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";
import { mapGetters } from "vuex";
import router from "@/router";

@Options({
  components: {
    SectionTitle,
    LeftStepBar,
  },
  // mounted() {
  //   this.$store.dispatch("questionnaire/getRiskProfiles");
  // },
  beforeMount() {
    this.$store.commit("onboarding/checkProfile");
  },
  methods: {},
  computed: {
    ...mapGetters({}),
  },
})
export default class QuestionnaireInfo extends Vue {}
