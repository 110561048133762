import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "\n      md:p-12\n      mt-5\n      md:bg-gray-100 md:border md:border-secondary-100 md:shadow\n      w-full\n    " }
const _hoisted_2 = { class: "text-xl font-medium flex items-start space-x-1 text-2xl" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text-2xl" }
const _hoisted_5 = {
  key: 0,
  class: "mt-8 py-5"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "font-serif mt-8" }
const _hoisted_8 = {
  key: 2,
  class: "font-serif mt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.step)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.step) + ".", 1))
        : _createCommentVNode("", true),
      _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.title), 1)
    ]),
    (!_ctx.perex)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.perex)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: "mt-8",
          innerHTML: _ctx.perex
        }, null, 8, _hoisted_6))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.description1), 1),
    (_ctx.description2)
      ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.description2), 1))
      : _createCommentVNode("", true)
  ]))
}