
import { Options, Vue } from "vue-class-component";
import DotsIcon from "@/assets/icons/DotsIcon.vue";

@Options({
  components: {
    DotsIcon,
  },
  props: {
    step: Number,
    title: String,
    perex: String,
    description1: String,
    description2: String,
    description3: String,
  },
})
export default class SectionTitle extends Vue {}
